.image {
  position: relative;

  &.lazy-load {
    &.loaded {
      img {
        display: block;
      }
    }
    img {
      display: none;
    }
  }
  img,
  .video-player {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .image-height-setter {
    display: block;
  }
  .video-player {
    z-index: 2;
    height: 100%;
    pointer-events: none;
    .video-wrap {
      height: 100%;
      video {
        // assume user will upload video with right dimensions
        min-height: 100%;
        min-width: 100%;
        visibility: visible;
        opacity: 0;
        transition: none;
      }
    }
    &.videoplayer-playing,
    &.videoplayer-paused {
      .video-wrap video {
        opacity: 1;
        transition: 0s opacity ease 0.1s;
      }
    }
  }
}

// .mobile-carousel only becomes a carousel on mobile screen sizes
.carousel,
.mobile-carousel {
  .caption {
    padding: 12px 0 0;
    @include fugue(14px);

    p {
      margin: 0;
    }
  }
  .flickity-page-dots {
    padding: 0 10px;
    text-align: center;
    display: none;

    .dot {
      width: 12px;
      height: 12px;
      opacity: 1;
      margin: 0 4px;
      background: $charcoal;
      border: 1px solid $charcoal;
      color: $charcoal;

      &.is-selected,
      .no-touchevents &:hover {
        background: $grey;
        border-color: $grey;
      }
      @include mobile-only {
        width: 8px;
        height: 8px;
      }
    }
    @include mobile-only {
      position: absolute;
      display: block;
      bottom: 20px;
    }
  }
  .flickity-prev-next-button {
    background: none !important;
    top: calc(100% + 25px);
    @include fugue(20px);

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
    &.previous:after {
      content: 'â';
    }
    &.next:after {
      content: 'â';
    }
    svg {
      display: none;
    }
  }
  &.flickity-enabled {
    img {
      // prevent it capturing drags
      pointer-events: none;
    }
  }
}

@mixin carousel-tabs($parent-cols: $total-cols) {
  .tab-wrap {
    padding-top: 75px;

    .tab {
      @include heldane(16px);
      $col-width: (($parent-cols - (3 * 2)) / 4);
      width: percentage($col-width / $parent-cols);
      margin-right: percentage(2/$parent-cols);

      &.is-selected {
        .caption-text {
          opacity: .275;
        }
      }
      .number {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

@mixin carousel-enabled {
  &.flickity-enabled {
    &.is-fullscreen {
      padding-bottom: 0;

      .carousel-item {
        height: 100%;
      }
      .flickity-fullscreen-button {
        display: none;
      }
    }
    .carousel-item {
      display: block;
    }
    .flickity-fullscreen-button {
      display: none;
    }
  }
  .carousel-item {
    width: 100%;
    display: none;

    &:first-child {
      display: block;
    }
  }
}

.carousel {
  @include carousel-enabled;
}
.mobile-carousel {
  @include mobile-only {
    @include carousel-enabled;
  }
}

@keyframes cursor-loading {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.2169312169);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes cursor-off {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes cursor-on {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

#cursor {
  pointer-events: none;

  .cursor-inner {
    @include fugue(22px);
    color: $off-white;
    background-color: $black;
    border-radius: 164px;
  }
  @include desktop-only {
    position: fixed;
    width: 63px;
    display: none;
    z-index: 15;
    left: 0px;
    top: 0px;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;

    .state-cursor-visible &,
    .state-loading &,
    .is-flickity-fullscreen &,
    .state-page-transition & {
      display: block;
    }
    .cursor-inner {
      position: relative;
      padding-top: 100%;
      will-change: transform;
      z-index: 15;

      .state-cursor-link &,
      .state-cursor-right &,
      .state-cursor-left & {
        &:before {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          will-change: transform;
          transform: translate3d(-50%, -50%, 0);
        }
      }
      .state-cursor-link & {
        .is-flickity-fullscreen & {
          &:before {
            content: 'Ã';
          }
        }
        &:before {
          content: '+';
        }
      }
      .state-cursor-link.is-flickity-fullscreen & {
        &:before {
          content: 'Ã';
        }
      }
      .state-cursor-right & {
        &:before {
          content: 'â';
          transform: translate3d(-50%, calc(-50% + 3px), 0);
        }
      }
      .state-cursor-left & {
        &:before {
          content: 'â';
          transform: translate3d(-50%, calc(-50% + 3px), 0);
        }
      }
      .state-loading &,
      .state-page-loading & {
        animation: cursor-loading 2s infinite;

        &:before {
          content: '';
        }
      }
      .state-project-hover-on & {
        animation: cursor-on .25s linear 0s 1 normal forwards;
      }
      .state-project-hover-off & {
        animation: cursor-off .25s linear 0s 1 normal forwards;
      }
    }
    .cursor-text {
      position: absolute;
      @include ib-grid(8, 2);
      display: none;
      top: calc(100% + 10px);

      .state-cursor-text-left.state-cursor-left & {
        display: block;
      }
      .state-cursor-text-right.state-cursor-right & {
        display: block;
      }
      p {
        margin: 0;
      }
    }
  }
  @include mobile-only {
    display: none !important;
  }
}

.title-banner-wrap {
  color: $black;
  visibility: hidden;
  opacity: 0;
  will-change: opacity transform;
  pointer-events: none;

  .state-banner-loaded &,
  .state-site-loaded & {
    &.loaded {
      opacity: 1;
    }
  }
  .state-carousel-first & {
    &.loaded {
      opacity: 0;
    }
  }
  .state-project-loaded & {
    &.loaded {
      opacity: 1;
    }
  }
  .state-page-transition & {
    transition-delay: 0;

    &.loaded {
      opacity: 0;
    }
  }
  @include mobile-only {
    .project-detail & {
      &.loaded {
        opacity: 1;
      }
    }
    .state-page-transition &,
    .state-carousel-first & {
      &.loaded {
        opacity: 0 !important;
      }
    }
  }
  h1 {
    line-height: .95;
    white-space: nowrap;
    font-weight: normal;
    user-select: none;
    will-change: transform;

    &:last-child {
      display: inline-block;
      opacity: 0;
    }
  }
  @include desktop-only {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 8;
    transform: translate3d(0, -50%, 0);

    &.loaded {
      &.positioning {
        h1 {
          transition: none;
        }
      }
      h1 {
        transition: .75s transform ease-in-out;
      }
    }
    .state-carousel-hover & {
      z-index: -1;
    }
    .is-flickity-fullscreen & {
      visibility: hidden !important;
    }
    .is-flickity-fullscreen.state-carousel-first & {
      visibility: inherit !important;
      z-index: 8;
    }
    h1 {
      position: absolute;
      margin: 0;
      left: 0;
      top: 0;
    }
  }
  @include mobile-only {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    body.project-detail &,
    body.info & {
      position: absolute;
      height: 100%;
      overflow: hidden;
    }
    h1 {
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      transform-origin: bottom left;
    }
  }
}

.page-title-wrap {
  @include fugue(17px);
  line-height: 1.2941176471;

  .home {
    &:hover,
    &.current {
      color: $grey;
    }
  }
  .info-wrap {
    a {
      @include no-underline;
      color: $grey;

      &:hover {
        color: $black;
      }
    }
    ul {
      list-style: none;
    }
  }
  @include desktop-only {
    @include ib-grid(34);
    @include prefix(1);
    @include suffix(1);
    padding: 30px 0;

    & > .text-wrap {
      @include ib-grid(34, 34);
    }

    .home,
    .client,
    .title,
    .year {
      display: inline-block;
    }
    .home,
    .title,
    .year {
      @include suffix(1, 34);
    }
    .client {
      @include suffix(.5, 34);
    }
    .info {
      position: relative;
      @include ib-grid(10, 34);
      opacity: 0;
      transition: opacity .25s;
      will-change: opacity;

      .state-title-show-sub.state-title-show-sub-in.state-carousel-first & {
        opacity: 1;
      }
      .state-page-loading & {
        opacity: 0 !important;
      }
      .info-inner {
        position: absolute;
        max-width: 465px;
        top: 0;
        pointer-events: none;
      }
    }
    .info-wrap {
      @include ib-grid(24, 34);
      opacity: 0;
      transition: opacity .25s;
      will-change: opacity;

      .state-title-show-sub & {
        opacity: 1;
      }
      h4 {
        margin: 0 0 1em;
      }
      .intro-wrap {
        @include ib-grid(10, 24);
        @include suffix(2, 24);
      }
      .client-wrap {
        @include ib-grid(6, 24);
        @include suffix(1, 24);

        ul {
          margin: 0;
          padding: 0;
        }
      }
      .contact-wrap {
        @include ib-grid(6, 24);
      }
    }
    .text-wrap {
      & > a,
      & > span {
        display: inline-block;
        transform: translateY(calc(-100% - 30px));
      }
    }
    body.projects & {
      position: fixed;
      z-index: 10;
    }
    .is-flickity-fullscreen & {
      display: none;
    }
  }
  @include mobile-only {
    display: none;
  }
}
