body,
table,
input,
textarea,
select {
  @include body-type;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

p {
  margin: 0 0 1em;

  > a {
    @include text-link;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}
