.main-nav,
.page-title-wrap .text-wrap > a,
.page-title-wrap .text-wrap > span {
  .state-initialised &,
  .state-loading & {
    transition: $transition-speed transform;
  }
}

.page-title-wrap {
  .text-wrap {
    & > span {
      .transition-out & {
        transform: translateY(calc(-100% - 30px)) !important;
      }
    }
    & > a {
      .state-title-show-main & {
        transform: translateY(0);
      }
    }
    & > span {
      & {
        .state-title-show-sub & {
          transform: translateY(0);
        }
      }
    }
    & > a,
    & > span {
      .transition-out & {
        transition-delay: .75s;
      }
      body.home & {
        transform: translateY(calc(-100% - 30px)) !important;
      }
    }
  }
}

.title-banner-wrap {
  h1 {
    .state-page-transition & {
      transition: none !important;
    }
  }
  @include desktop-only {
    .state-project-loaded & {
      transition: 2s transform;
      transition-timing-function: $bezier;
    }
    .state-project-loaded.state-carousel-viewing & {
      transform: translate3d(0, -50%, 0);
    }
  }
}

header#header {
  .main-nav {
    .state-site-loaded & {
      transform: translateY(0);
    }
  }
  @include mobile-only {
    .state-initialised &,
    .state-loading & {
      .main-nav {
        transition: $transition-speed opacity .5s;
      }
    }
    .state-loading & {
      .main-nav {
        opacity: 0;
      }
    }
  }
}

article.portfolio-home {
  .state-initialised &,
  .state-loading & {
    .featured-wrap {
      transition: transform 2s;
      transition-timing-function: $bezier;
    }
    .title-banner-wrap {
      transition: 2s transform .5s;
      transition-timing-function: $bezier;
    }
  }
  .state-home-loaded & {
    .featured-wrap {
      transition: #{opacity .75s, visibility .75s, transform 2s};
      transition-timing-function: $bezier;
      transform: translateX(0);
    }
  }
  &.transition-out {
    .featured-wrap {
      &.initialised-size {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

article.portfolio-projects {
  .state-initialised &,
  .state-loading & {
    .project-list {
      transition: transform .75s;
    }
    .title-banner-wrap {
      transition: 2s transform .25s;
      transition-timing-function: $bezier;
    }
  }
  .state-projects-loaded & {
    .project-list {
      transform: translate3d(0, 0, 0);
    }
  }
  &.transition-out {
    .project-list {
      visibility: hidden;
    }
    .title-banner-wrap {
      // transform: translate3d(-150%, -50%, 0);
    }
  }
}

article.portfolio-project-detail {
  .state-initialised &,
  .state-loading & {
    .carousel-item {
      transition: opacity .75s;
    }
  }
  .state-project-loaded & {
    .project-wrap {
      .carousel-item {
        opacity: 1;
      }
    }
  }
  &.transition-out {
    .project-wrap {
      .carousel-item {
        transition-delay: .75s;
        opacity: 0;
      }
    }
  }
}

article.jamiemclellan-info {
  .state-initialised &,
  .state-loading & {
    .intro-wrap {
      transition: $transition-speed opacity;
    }
  }
  .state-info-loaded & {
    .intro-wrap {
      opacity: 1;
    }
  }
  &.transition-out {
    .intro-wrap {
      transition-delay: 0s;
      opacity: 0;
    }
  }
}
