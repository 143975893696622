article.portfolio-projects {
  .project-list {
    opacity: 1;
    will-change: opacity;

    @include desktop-only {
      @include ib-grid(34);
      @include prefix(1);
      @include suffix(1);
      padding-bottom: 500px;
      transform: translate3d(0, 100vh, 0);

      .project-listing {
        position: relative;
        @include ib-grid(11, 34);

        &:nth-child(6n+1) {
          @include prefix(23, 34);
        }
        &:nth-child(6n+2) {
          @include suffix(23, 34);
          margin-top: percentage(-5.75/34);
          margin-bottom: percentage(3/34);
        }
        &:nth-child(6n+3) {
          @include ib-grid(10, 34);
          @include prefix(12, 34);
          @include suffix(12, 34);
          margin-bottom: percentage(1.25/34);
        }
        &:nth-child(6n+4) {
          @include suffix(12, 34);
        }
        &:nth-child(6n+5) {
          margin-top: percentage(2.5/34);
        }
        &:nth-child(6n+6) {
          @include ib-grid(10, 34);
          @include prefix(12, 34);
          @include suffix(12, 34);
          margin-top: percentage(-2.75/34);
          margin-bottom: percentage(2.75/34);
        }
        h3 {
          display: none;
        }
        .image-wrap {
          position: relative;
          padding-top: 100%;

          .image {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            visibility: hidden;

            &.active {
              visibility: inherit;
            }
          }
        }
      }
    }
    @include mobile-only {
      @include ib-grid(35);
      @include prefix(.5);
      @include suffix(.5);
      $third: 16 / 3;
      padding-top: 30px;

      .state-menu-visible & {
        visibility: hidden;
      }
      .project-listing {
        @include ib-grid(16.5, 35);
        @include prefix(.5, 35);
        @include suffix(.5, 35);
        margin-bottom: percentage($third/35);

        &:nth-child(2) {
          margin-top: percentage(($third * 2)/35);
        }
        &:nth-child(2n+3) {
          margin-top: percentage(($third * -2)/35);
        }
        &:nth-child(2n+4) {
          margin-top: 0;
        }
        h3 {
          display: none;
        }
        .image-wrap {
          .image {
            display: none;

            &:first-child {
              display: block;
            }
          }
        }
      }
    }
  }
}


article.portfolio-project-detail {
  * {
    user-select: none;
  }
  @include desktop-only {
    height: 100vh;
    overflow: hidden;
  }
  @include mobile-only {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .state-menu-visible & {
      overflow: hidden;
    }
  }
  .project-wrap {
    &, * {
      cursor: none !important;
    }
    .carousel-wrap {
      visibility: hidden;

      &.disabled {
        pointer-events: none;
      }
      &.initialised-size {
        visibility: inherit;
      }
      .carousel-item {
        .is-flickity-fullscreen & {
          background: $off-white;
        }
      }
    }
    .carousel-item {
      will-change: opacity;
      opacity: 0;

      &.image-cell {
        .caption {
          @include fugue(14px, $mobile-font-max: 11px);
        }
      }
    }
    .image-wrap {
      @include fugue(16px);
      text-align: center;
    }
    .description-wrap {
      .info {
        @include fugue(29px);

        @include mobile-only {
          @include fugue(14px);
          line-height: 1.2;
        }
      }
      .additional {
        @include fugue(17px);

        @include mobile-only {
          @include fugue(14px);
          line-height: 1.2;
        }
      }
    }
    @include desktop-only {
      margin-top: 144px;

      .carousel {
        &.is-fullscreen {
          .carousel-item {
            margin: 0;

            .image-wrap {
              width: 100%;

              .image {
                margin: 0;
              }
            }
          }
        }
      }
      .carousel-item {
        @include prefix(1);
        @include suffix(1);

        &.text-cell {
          height: calc(100vh - 168px);

          .is-flickity-fullscreen & {
            height: 100vh;
          }
        }
        &.image-cell {
          position: relative;

          .caption {
            position: absolute;
            padding: 12px 0;
            width: 100%;
            bottom: 0;
            text-align: center;

            .is-flickity-fullscreen & {
              display: none;
            }
          }
          .project-inner {
            .is-flickity-fullscreen & {
              position: absolute;
              width: 100%;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
        .description-wrap {
          @include ib-grid(34);
          @include prefix(1);
          @include suffix(1);
          display: none;

          .info {
            position: absolute;
            @include ib-grid(23, 34);
            @include suffix(11, 34);
            top: calc(50vh - 144px);
            transform: translateY(calc(-100% - 30px));
            margin-bottom: 30px;

            .is-flickity-fullscreen & {
              top: 50vh;
              transform: translateY(calc(-100% - 30px));
            }
            & > :last-child {
              margin: 0;
            }
          }
          .additional {
            position: absolute;
            @include ib-grid(11, 34);
            @include suffix(23, 34);
            top: calc(50vh - 144px);
            margin-top: 30px;

            .is-flickity-fullscreen & {
              top: 50vh;
            }
            & > :last-child {
              margin: 0;
            }
          }
          .mobile-title-wrap {
            display: none;
          }
        }
        .image-wrap {
          @include cols(30, 34);
          margin-left: auto;
          margin-right: auto;

          .mobile-only {
            display: none;
          }
        }
      }
      .mobile-buttons {
        display: none;
      }
    }
    @include mobile-only {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      .carousel-wrap {
        position: absolute;
        width: 100%;
        padding-top: 100%;
        top: 50%;
        transform: translateY(-50%);

        .carousel {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        @include landscape-only {
          height: 100%;
          padding-top: 0;

          .carousel {
            position: static;
            width: 100%;
            height: 100%;
            top: auto;
            left: auto;
          }
        }
        .state-menu-visible & {
          visibility: hidden;
        }
      }
      .mobile-buttons {
        position: absolute;
        @include ib-grid(34);
        @include prefix(1);
        @include suffix(1);
        padding: 10px 0;
        top: calc(100% + 25px);

        @include landscape-only {
          top: auto;
          bottom: 0;
        }
        button {
          position: relative;
          @include fugue(11px);
          @include ib-grid(4, 34);
          outline: none;
          background: none;
          border: 0;
          margin: 0;
          padding: 0;
          text-align: right;

          &.carousel-next {
            @include ib-grid(12, 34, $gutter: 0);
            @include prefix(21, 34, $gutter: 0);
            display: none;

            .state-cursor-text-right:not(.state-page-transition) & {
              display: inline-block;
            }
            &:after {
              position: absolute;
              @include fugue(20px);
              line-height: 1;
              display: inline-block;
              content: 'â';
              bottom: 100%;
              transform: translateY(2px);
              right: 0;
            }
            span {
              @include fugue(11px);
              display: inline-block;
              white-space: nowrap;
            }
          }
        }
      }
      .carousel-item {
        @include ib-grid(32);
        @include prefix(2);
        @include suffix(2);
        overflow: hidden;

        @include landscape-only {
          height: 100%;
        }

        &.image-cell {
          top: 50%;
          transform: translateY(-50%);

          @include landscape-only {
            top: auto;
            transform: none;
          }
          .caption {
            display: none;
          }
        }
        &.text-cell {
          padding-top: percentage(32/$total-cols);

          @include landscape-only {
            padding-top: 0;
            height: 100%;
          }
        }

        .description-wrap {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;

          .description-inner {
            padding: 40px percentage(2/$total-cols) 40px;
          }
          @include landscape-only {
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);

            .description-inner {
              padding-top: 30px;
              padding-bottom: 30px;
            }
          }
          .mobile-title-wrap {
            margin-bottom: 40px;

            span {
              display: block;
            }
          }
          .info {
            margin-bottom: 40px;

            & > :last-child {
              margin: 0;
            }
          }
          .additional {
            & > :last-child {
              margin: 0;
            }
          }
        }
        .project-inner {
          position: relative;
          overflow: hidden;

          .image-wrap {
            margin-left: 50%;
            transform: translateX(-50%);

            @include landscape-only {
              margin-top: 50vh;
              transform: translate3d(-50%, -50%, 0);
            }
            &.has-mobile {
              & > .image {
                display: none;
              }
              @include landscape-only {
                & > .image {
                  display: block;
                }
                .mobile-only {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

article.jamiemclellan-info {
  @include desktop-only {
    padding-top: 144px;

    .title-banner-wrap {
      display: none;
    }
  }
  @include mobile-only {
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 85px 0 135px;

    .state-menu-visible & {
      visibility: hidden;
      overflow: hidden;
    }
  }
  .intro-wrap {
    opacity: 0;
    will-change: opacity;

    ul {
      list-style: none;

      @include fugue(16px);

      @include mobile-only {
        @include fugue(13px);
      }
    }

    .text-wrap,
    .contact-wrap {
      @include fugue(16px);

      a {
        @include no-underline;
      }
      @include mobile-only {
        @include fugue(13px);
      }
    }
    @include desktop-only {
      display: none;
    }
    @include mobile-only {
      @include ib-grid(28);
      @include prefix(4);
      @include suffix(4);
      padding: 45px 0 15px;

      @include landscape-only {
        @include ib-grid(20);
        @include prefix(8);
        @include suffix(8);
        padding-top: 0;
      }

      h4 {
        margin: 0 0 1em;
      }
      ul {
        margin: 0 0 1em;
        padding: 0;
      }
      .text-wrap {
        &.intro {
          margin-bottom: 40px;
        }
        &.contact {
          .text {
            p {
              margin: 0 0 30px;
            }
          }
        }
        .text {
          :last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
