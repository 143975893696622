$hamburger-layer-color: $charcoal;
$hamburger-layer-border-radius: 0px;
$hamburger-layer-width: 25px;
$hamburger-layer-height: 2px;
$hamburger-padding-y: 10px;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
  padding: percentage(2/$total-cols);

  .hamburger-inner {
    &:after {
      display: none;
    }
  }
}
