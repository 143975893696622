// Theme
$black: #000;
$charcoal: #2a2a2a;
$white: #fff;
$grey: #bbb;
$off-white: #f8f8f8;

$transition-speed: .75s;
$bezier: cubic-bezier(.29,.49,0,.97);

$iphone-bottom-bar: 112px;

// Structural constants and helper mixins

$min-fluid-width: 320px;
$max-fluid-width: 6000px;
$max-desktop-small: 1200px;

$design-width: 1440px; // reference width from the design
$design-gutter: 5px; // actually 5px
$max-mobile: 1024px; // includes ipad portrait if bigger than $max-phone
$max-tablet: 1024px;
$total-cols: 36;
$col-width: percentage(1 / $total-cols);
$default-gutter: percentage($design-gutter / $design-width);
$fallback-width: 1200px; // used in place of 100vw where vw not supported
$mobile-padding: 15px;
$short-screen-cutoff: 720px;

@mixin grid-container {
  max-width: $max-fluid-width;
  min-width: $min-fluid-width - $mobile-padding * 2;
  margin: 0 auto;
}

@mixin gutters($gutter: $default-gutter, $parent-cols: $total-cols) {
  // Add left/right margin according to the grid gutter width.
  $factor: $total-cols / $parent-cols;
  margin-left: $gutter * $factor;
  margin-right: $gutter * $factor;
}

@function col-width($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  $factor: $total-cols / $parent-cols;
  @return ($cols * $col-width - $gutter * 2) * $factor;
}

@mixin cols($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  // Set width to the specified number of cols, with gutters.
  width: col-width($cols, $parent-cols, $gutter);
  @include gutters($gutter, $parent-cols);
}

@mixin ib-grid($width: null, $args...) {
  // wrapper for cols() which also makes the element an inline-block column
  // if first argument is a percentage, use that for width. Otherwise calculate
  // width according to the grid
  display: inline-block;
  vertical-align: top;
  @if $width {
    @if unit($width) == '%' {
      width: $width;
    } @else {
      @include cols($width, $args...);
    }
  }
}

@mixin prefix($cols: 0, $parent-cols: $total-cols, $gutter: $default-gutter,
              $prop: margin) {
  // Add left margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}-left: (($col-width * $cols) + $gutter) * $factor;
}

@mixin suffix($cols: 0, $parent-cols: $total-cols, $gutter: $default-gutter,
              $prop: margin) {
  // Add right margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}-right: (($col-width * $cols) + $gutter) * $factor;
}

@function grid-px($cols, $gutter: $default-gutter) {
  // Calculate the pixel width of a fluid grid element, at $design-width
  @return round(($cols / $total-cols - 2 * $gutter / 100%) * $design-width);
}

@mixin vw-prop($prop, $design-val: 0, $min-width: ($max-mobile + 1),
               $max-width: $max-fluid-width, $val: 0) {
  // Set the value of $prop such that the value is fluid when the browser
  // width is within $min-width and $max-width, and fixed outside this range.
  // When the browser width is within the range, use vw, and outside use px.
  // If $design-val is set, use it to derive $val, as a percentage (in vw) of
  // $design-width. Otherwise, use $val to derive the min and max pixel values.

  @if ($design-val != 0) {
    // assume $design-val is in px
    $val: $design-val / $design-width * 100;
  }

  @if $allow-mq {
    @include restricted-vw-property($prop, $val, $min-width, $max-width);
  } @else {
    #{$prop}: $val * $fallback-width / 100;
  }
}

@mixin desktop-only {
  @if $allow-mq {
    @media screen and (min-width: #{$max-mobile + 1}) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin mobile-only {
  @if $allow-mq {
    @media screen and (max-width: $max-mobile) {
      @content;
    }
  }
}

@mixin landscape-only {
  @if $allow-mq {
    @media screen and (orientation:landscape) {
      @content;
    }
  }
}

@mixin ios-set-height($val) {
  @if $allow-mq {
    /**
     * iPad with portrait orientation.
     */
    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
      height: #{1024px - $val};
    }

    /**
     * iPad with landscape orientation.
     */
    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape){
      height: #{768px - $val};
    }

    /**
     * iPhone 5
     * You can also target devices with aspect ratio.
     */
    @media screen and (device-aspect-ratio: 9/16) {
      height: #{500px - $val};
    }
  }
}

// Miscellaneous constants and helpers
// see also constants.js

$content-nav-height: 50px;
$min-header-height: 600px;
$nav-links-height: 120px;

@mixin anchor-offset($padding: 0) {
  padding-top: $content-nav-height + $padding;
  margin-top: $content-nav-height * -1;
}

@mixin underline($color: currentColor, $weight: .08em, $offset: .02em) {
  text-decoration: none;
  background-image: unquote('linear-gradient(')to top,
    transparent $offset,
    $color $offset,
    $color ($offset + $weight),
    transparent ($offset + $weight)unquote(')');
}
@mixin no-underline {
  background-image: none;
}

@mixin hover-state($touch-default: false) {
  /* Enable hover states on non-touch devices only. If $touch-default,
     default to the hovered state for touch devices.
     Requires Modernizer.touchevents */

  .no-touchevents &:hover {
    @content;
  }
  @if $touch-default {
    .touchevents & {
      @content;
    }
  }
}
