@mixin lock-screen {
  width: 100% !important;
  height: calc(100vh - 1px) !important;
  overflow: hidden !important;
}

html {
  overflow-y: scroll;

  &.state-loading {
    @include lock-screen;
    overflow-y: scroll !important;
  }
  @include mobile-only {
    padding: env(safe-area-inset);

    &.state-menu-visible {
      @include lock-screen;
    }
  }
}
body {
  background: $off-white;
  color: $charcoal;
  visibility: hidden;

  &.state-initialised {
    visibility: inherit;
  }
  .state-cursor-visible &,
  .state-loading &,
  .state-page-transition & {
    * {
      cursor: none !important;
    }
  }
  .page-wrap {
    position: static;
    z-index: 1;
  }
  @include mobile-only {
    background: $white;

    &:after {
      position: fixed;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -2;
      background: $off-white;
    }
    &.project-detail {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .state-menu-visible & {
      @include lock-screen;
    }
    .page-wrap {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      // padding: env(safe-area-inset);
    }
  }
}

body.projects {
  @include mobile-only {
    .page-wrap {
      position: static;
      height: auto;
      top: auto;
      transform: none;
    }
  }
}

#content {
  position: relative;

  @include desktop-only {
    min-height: 100vh;
    z-index: 2;

    .state-project-hover & {
      z-index: 9;
    }
  }
  @include mobile-only {
    z-index: 4;
    height: 100%;
  }
  > article {
    @include grid-container;
  }
}

.no-desktop {
  @include desktop-only {
    display: none !important;
  }
}
