$base-path: './';

@mixin webfont($name, $filename, $weight: 400, $style: normal, $woff2: false) {
  @font-face {
    font-family: "#{$name}";
    src: url($base-path + $filename + '.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url($base-path + $filename + '.woff2'), // advanced browsers
         url($base-path + $filename + '.woff') format('woff'); // other proper browsers
    font-weight: $weight;
    font-style: $style;
  }
}

@font-face {
  font-family: "Fugue";
  src: url('./fugue_regular.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
}
