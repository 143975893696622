@import '../lib/type_mixins';

@mixin fugue($font-size: null, $font-resize-cutoff: 960px,
             $mobile-font-max: null) {

  font-family: "Fugue", 'Futura', sans-serif;

  @if $font-size {
    @include smart-font-size(
      $font-size,
      $lh-max: 1.5,
      $lh-lower-cutoff: 12px,
      $lh-min: 1.2,
      $lh-upper-cutoff: 30px,

      $ls-max: 0px,
      $ls-lower-cutoff: 10px,
      $ls-min: 0px,
      $ls-upper-cutoff: 130px,

      $font-resize-cutoff: $font-resize-cutoff,
      $mobile-font-max: $mobile-font-max);
  }
}

// these mixins define reusable groups of font-related styles, and should be
// used where possible to keep type styles consistent around the site
//
// mixin names should describe the appearance, not the usage, i.e.
// "national-large-heading" not "bulletin-article-heading"

$body-font-size: 16px;

@mixin body-type {
  @include fugue($body-font-size);
}


@mixin text-link {
  @include underline;

  .no-touchevents &:hover,
  &.current {
    @include no-underline;
  }
}

@mixin stealth-link {
  @include no-underline;

  .no-touchevents &:hover,
  &.current {
    @include underline;
  }
}
