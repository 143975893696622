article.portfolio-home {
  @include desktop-only {
    height: 100vh;
    overflow: hidden;
  }
  @include mobile-only {
    height: auto;
  }
  .featured-wrap {
    visibility: hidden;

    &.initialised-size {
      visibility: inherit;
    }
    @include desktop-only {
      @include ib-grid(36);
      padding: 140px 0;
      height: 100vh;
      transform: translateX(100%);

      .carousel-item {
        .image-wrap {
          display: block;
          margin: 0 auto;

          .mobile-only {
            display: none;
          }
        }
        .project-link {
          display: none;
        }
      }
    }
    @include mobile-only {
      position: relative;
      height: calc(100vh - #{$iphone-bottom-bar});

      &.initialised-size {
        .carousel-wrap {
          transform: translate3d(0, -50%, 0);

          @include landscape-only {
            transform: translate3d(0%, -50%, 0);
          }
        }
      }
      .state-menu-visible & {
        visibility: hidden;
        transition: none;
      }
      .carousel-wrap {
        position: absolute;
        width: 100%;
        padding-top: 100%;
        top: 50%;
        transform: translate3d(150%, -50%, 0);
        transition: $transition-speed transform;

        @include landscape-only {
          height: 100%;
          padding-top: 0;
        }

        .carousel {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .flickity-prev-next-button {
            display: none;
          }
        }
      }
      .carousel-item {
        @include ib-grid(32);
        @include prefix(2);
        @include suffix(2);
        overflow: hidden;

        top: 50%;
        transform: translateY(-50%);

        @include landscape-only {
          top: auto;
          transform: none;
        }
        .image-wrap {
          margin-left: 50%;
          transform: translateX(-50%);

          @include landscape-only {
            margin-top: 50vh;
            transform: translate3d(-50%, -50%, 0);
          }
          &.has-mobile {
            & > .image {
              display: none;
            }
            @include landscape-only {
              & > .image {
                display: block;
              }
              .mobile-only {
                display: none;
              }
            }
          }
        }
        .project-link {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .project-list {
    .project-listing {
      .state-project-hover & {
        &.project-active {
          .image-wrap {
            img {
              // @include grayscale(0%);
              z-index: 9;
            }
          }
        }
        .image-wrap {
          img {
            @include grayscale(100%);
          }
        }
      }
      .image-wrap {
        img {
          opacity: .95;
          @include grayscale(0%);
          transition: #{filter .4s, opacity .4s};
          will-change: filter;

          @include mobile-only {
            opacity: 1;
          }
        }
      }
    }
    @include desktop-only {
      @include ib-grid(34);
      @include prefix(1);
      @include suffix(1);
      padding-bottom: 500px;

      .project-listing {
        @include ib-grid(11, 34);

        &:nth-child(6n+1) {
          @include prefix(23, 34);
        }
        &:nth-child(6n+2) {
          @include suffix(23, 34);
          margin-top: percentage(-5.75/34);
          margin-bottom: percentage(3/34);
        }
        &:nth-child(6n+3) {
          @include ib-grid(10, 34);
          @include prefix(12, 34);
          @include suffix(12, 34);
          margin-bottom: percentage(1.25/34);
        }
        &:nth-child(6n+4) {
          @include suffix(12, 34);
        }
        &:nth-child(6n+5) {
          margin-top: percentage(2.5/34);
        }
        &:nth-child(6n+6) {
          @include ib-grid(10, 34);
          @include prefix(12, 34);
          @include suffix(12, 34);
          margin-top: percentage(-2.75/34);
          margin-bottom: percentage(2.75/34);
        }
        h3 {
          display: none;
        }
        .image-wrap {
          position: relative;
          padding-top: 100%;

          .image {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            visibility: hidden;

            &.active {
              visibility: inherit;
            }
          }
        }
      }
    }
    @include mobile-only {
      $third: 16 / 3;
      padding-top: 30px;

      .state-menu-visible & {
        visibility: hidden;
      }
      .project-listing {
        @include ib-grid(16);
        @include prefix(1);
        @include suffix(1);
        margin-bottom: percentage($third/$total-cols);

        &:nth-child(2) {
          margin-top: percentage(($third * 2)/$total-cols);
        }
        &:nth-child(2n+3) {
          margin-top: percentage(($third * -2)/$total-cols);
        }
        &:nth-child(2n+4) {
          margin-top: 0;
        }
        h3 {
          display: none;
        }
        .image-wrap {
          .image {
            display: none;

            &:first-child {
              display: block;
            }
          }
        }
      }
    }
  }
}
