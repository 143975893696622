header#header {
  @include grid-container;

  @include desktop-only {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .main-nav {
    @include desktop-only {
      body.info & {
        background: rgba($off-white, .9);
      }
    }
    @include mobile-only {
      & {
        background: $white;
      }
    }
    .link-home {
      @include fugue(16px);

      @include mobile-only {
        @include fugue(28px, $mobile-font-max: 28px);
      }
    }
    .links {
      & > a {
        @include fugue(18px, $mobile-font-max: 28px);

        @include mobile-only {
          @include fugue(28px, $mobile-font-max: 28px);
        }
        &:hover,
        &.current {
          color: $grey;
        }
      }
    }
  }

  @include desktop-only {
    .hamburger {
      display: none;
    }
    .main-nav {
      position: fixed;
      @include ib-grid(34);
      @include prefix(1);
      @include suffix(1);
      left: 0;
      bottom: 0;
      padding: 35px 0;
      transform: translateY(100%);
      z-index: 12;

      .is-flickity-fullscreen & {
        display: none;
      }
      .links {
        & > a {
          display: inline-block;
          @include gutters($parent-cols: 34);
          margin-right: 30px;
        }
      }
      .link-home {
        display: none;
      }
    }
  }
  @include mobile-only {
    .hamburger {
      position: fixed;
      right: 0;
      top: 0;
      z-index: 13;

      body.project-detail &,
      body.info & {
        position: absolute;
      }
    }
    .main-nav {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100vh;
      z-index: 12;
      padding: calc((100% / #{$total-cols}) * 4 + 25px) percentage(4/$total-cols) 0;
      display: none;

      @include ios-set-height(170px);

      .state-menu-visible & {
        display: block;
      }
      .link-home {
        display: block;
        margin-bottom: .5em;
      }
      .links {
        & > a {
          display: block;
          margin-bottom: .5em;
        }
      }
    }
  }
}
